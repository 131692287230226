import React from "react"
import { styled } from "linaria/react"
import Logo from "../../Images/Logo"
import Nav from "./Nav"
import Social from "../../Elements/Social"

const Container = styled.header`
  @media screen and (max-width: 900px) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 101;
`

const HorizontalDivider = styled.div`
  height: 1px;
  margin-top: 10px;
  background-color: #000;
  width: 40px;
`

const Text = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
`

const SocialContainer = styled.div`
  margin-left: auto;
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const Header = props => {
  return (
    <React.Fragment>
      <SocialContainer>
        <Social style={{ margin: "10px 10px 2px 0" }} />
        <HorizontalDivider />
        <Text>SOCIAL</Text>
      </SocialContainer>
      <Container>
        <Logo />
        <Nav toggleNav={props.toggleNav} />
      </Container>
    </React.Fragment>
  )
}

Header.propTypes = {}

export default Header
