import React from "react"
import Image from "../../images/Logo_Alt@2x.svg"
import { styled } from "linaria/react"
import Link from "gatsby-link"

const StyledLink = styled(Link)`
  z-index: 1;
  margin: auto;
  max-width: 489px;
  max-height: 77px;
  @media screen and (max-width: 900px) {
    padding: 15px 0;
  }
  @media screen and (max-width: 480px) {
    max-width: 80%;
    margin: auto;
  }
`

const StyledImage = styled.object`
  margin: auto;
  pointer-events: none;
  width: 100%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
const Logo = () => {
  return (
    <StyledLink to="/">
      <StyledImage
        data={Image}
        aria-label="Katelyn Fedrick Lifestyle Blog Logo"
      />
    </StyledLink>
  )
}

export default Logo
