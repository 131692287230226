/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { styled } from "linaria/react"

import "../layout.css"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import MobileNav from "./MobileNav/MobileNav"

const Page = styled.div`
  width: 100%;
  background: #f9f9f9;
  min-height: 100vh;
  .contentWrapper {
    max-width: 1440px;
    width: 100%;
    background: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  main {
    position: relative;
    min-height: 100vh;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

const Layout = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const toggleNav = () => {
    setVisible(!visible)
  }
  return (
    <Page visible={visible}>
      <div className="contentWrapper">
        <Header toggleNav={toggleNav} />
        <main>
          <MobileNav visible={visible} />
          {children}
        </main>
        <Footer />
      </div>
    </Page>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
