import React from "react"
import { styled } from "linaria/react"
import Link from "gatsby-link"
import { colors } from "../../theme"
const Wrapper = styled.div`
  transition: all 0.3s ease-out;
  overflow: hidden;
  display: flex;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 100 : 0)};
  position: fixed;
  top: 125px;
  left: 0;
  width: 100vw;
  height: ${props => (props.visible ? "100%" : "0%")};
  background: rgba(255, 255, 255, 0.95);
  padding: 50px 15px 15px 15px;
  z-index: 100;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 900px) and (min-width: 768px) {
    padding: 100px 15px 15px 15px;
  }
  @media screen and (max-width: 768px) and (min-width: 481px) {
    padding: 75px 15px 15px 15px;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin: 0 auto 30px auto;
  }
  > *:last-child {
    margin: 0 auto 0 auto;
  }
`
const StyledLink = styled(Link)`
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.black};
  &.active {
    color: ${colors.visited};
  }
`

const StyledA = styled.a`
  margin-top: 100px;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.black};
`

const MobileNav = props => {
  return (
    <Wrapper visible={props.visible}>
      <LinkWrapper>
        <StyledLink activeClassName="active" to={"/"}>
          Home
        </StyledLink>
        <StyledLink activeClassName="active" to={"/about/"}>
          About
        </StyledLink>
        <StyledLink activeClassName="active" to={"/mission/"}>
          Mission
        </StyledLink>
        <StyledLink activeClassName="active" to={"/blog/"}>
          Blog
        </StyledLink>
        <StyledA
          href="https://paypal.me/katelynfedrick"
          target="_blank"
          class="ctaLink"
        >
          <span>Donate</span>
        </StyledA>
      </LinkWrapper>
      {/* Todo
      <NavFooter visible={props.visible}>
        <Divider />
        <Horiz>
          <span>Privacy Policy</span>
          <span>Sitemap</span>
        </Horiz>
      </NavFooter>
      */}
    </Wrapper>
  )
}

export default MobileNav
