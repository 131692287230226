import React from "react"
import PropTypes from "prop-types"
const SocialIcon = props => {
  return (
    <a
      href={props.link}
      className="svg"
      target="_blank"
      rel="noreferrer"
      style={{ display: "flex" }}
    >
      <img
        alt={props.alt}
        src={props.data}
        style={{
          margin: "auto",
          width: props.width || "30px",
          height: props.height || "30px",
        }}
      />
    </a>
  )
}

SocialIcon.propTypes = {
  data: PropTypes.any.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  link: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default SocialIcon
