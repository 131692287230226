import React from "react"
import { styled } from "linaria/react"
import { Link } from "gatsby"
import { colors } from "../../theme"
import Social from "../../Elements/Social"
import { Squash as Hamburger } from "hamburger-react"

const Container = styled.nav`
  @media screen and (max-width: 900px) {
    margin-top: 30px;
    padding: 0 10px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
  background: ${colors.mainPink};
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.25);
  .wrapper {
    @media screen and (max-width: 900px) {
      display: none;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    max-width: 724px;
    .mainLink {
      padding: 10px;
      color: #2c2c2c;
      text-transform: uppercase;
      transition: color 0.2s ease-in;
      text-decoration: none;
      :hover {
        color: #914b49;
      }
    }
    .ctaLink {
      color: #fff;
      background: #2c2c2c;
      padding: 0 30px 0 30px;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .active {
      color: #914b49;
    }
  }
`

const MobileNav = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  display: none;
`

const Nav = props => {
  return (
    <Container>
      <div className="wrapper">
        <Link className="mainLink" activeClassName="active" to={"/"}>
          Home
        </Link>
        <Link className="mainLink" activeClassName="active" to={"/about/"}>
          About
        </Link>
        <Link className="mainLink" activeClassName="active" to={"/blog/"}>
          Blog
        </Link>
        <Link className="mainLink" activeClassName="active" to={"/mission/"}>
          Mission
        </Link>
        <a
          href="https://paypal.me/katelynfedrick"
          target="_blank"
          class="ctaLink"
        >
          <span>Donate</span>
        </a>
      </div>
      <MobileNav>
        <Social height="26px" width="26px" />
        <Hamburger
          label="Show Navigation"
          onToggle={toggled => {
            props.toggleNav()
          }}
        />
      </MobileNav>
    </Container>
  )
}

export default Nav
