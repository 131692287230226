import React from "react"
import { styled } from "linaria/react"
import { colors } from "../../theme"
import Link from "gatsby-link"
import Logo from "../../Images/Logo"
import Social from "../../Elements/Social"

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 99;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding-top: 30px;
`

const StyledLink = styled(Link)`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;

  color: #000000;
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  > * {
    margin-right: 40px;
  }
  > *:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    > * {
      margin: 0 auto 40px auto;
    }
    > *:last-child {
      margin: 0 auto 0 auto;
    }
  }
`

const MiniFooter = styled.div`
  @media screen and (max-width: 480px) {
    justify-content: space-between;
    padding: 10px 15px;
  }

  display: flex;
  flex-direction: row;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px;
  background-color: ${colors.mainPink};
  width: 100%;

  span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    &:first-child {
      @media screen and (max-width: 480px) {
        margin-left: 0;
      }
    }
  }
  > div {
    margin: auto;
    > * {
      margin: 0 40px 0 0;
    }
    > *:last-child {
      margin: 0;
    }
  }
`

const Header = props => {
  return (
    <Container>
      <Logo />
      <LinkWrapper>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/about/">About</StyledLink>
        <StyledLink to="/mission/">Mission</StyledLink>
        <StyledLink to="/blog/">Blog</StyledLink>
      </LinkWrapper>
      <Social style={{ marginTop: "50px", marginBottom: "5px" }} />
      <MiniFooter></MiniFooter>
    </Container>
  )
}

export default Header
