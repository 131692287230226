const colors = {
  mainPink: "#FFF9F9",
  darkPink: "#F2B7BD",
  black: "#242424",
  visited: "#A06262",
}

const fontSizes = {
  large: 44,
  medium: 35,
  small: 22,
  body: 16,
  mobileBody: 14,
}

export { colors, fontSizes }
