import React from "react"
import Icon from "./SocialIcon"
import { styled } from "linaria/react"
import Instagram from "../../images/instagram.svg"
import Facebook from "../../images/facebook.svg"
import Pinterest from "../../images/pinterest.svg"
import LTK from "../../images/Icons/LTK.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 20px;
  }
  > *:last-child {
    margin-right: 0;
  }
`
const Social = props => {
  const height = props.height || "20px"
  const width = props.width || "20px"
  return (
    <Wrapper style={props.style}>
      <Icon
        width={width}
        height={height}
        data={Instagram}
        alt="Instagram Icon"
        link="https://www.instagram.com/katelyn_fedrick"
      />
      <Icon
        width={width}
        height={height}
        data={Facebook}
        alt="Facebook Icon"
        link="https://www.facebook.com/profile.php?id=100011501707224"
      />
      <Icon
        width={width}
        height={height}
        data={Pinterest}
        alt="Pinterest Icon"
        link="https://www.pinterest.com/katelyn_fedrick/_saved/"
      />
      {/*  
      <Icon
        width={width}
        height={height}
        data={LTK}
        alt="Like To Know It Icon"
        link="http://liketoknow.it/katelyn_fedrick"
      />
      */}
    </Wrapper>
  )
}

export default Social
